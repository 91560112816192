<template>
  <div id="app">
    <h3>Projects</h3>
    <ul>
      <li><a href="https://hypercube.matt-schaffer.com" target="_blank" rel="noopener noreferrer">Hypercube</a></li>
      <li><a href="https://sudoku.matt-schaffer.com" target="_blank" rel="noopener noreferrer">Sudoku</a></li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'App',
}
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin-top: 60px;
}

li {
  list-style-type: none;
}

</style>
